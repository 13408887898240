<template>
  <div data-sel="frm-ao">
    <CRow>
      <CCol sm="1" class="col-img">
        <CImg
            :src="accountOwner.avatar? accountOwner.avatar.hrefOrig: null"
            fluid
            block
            align="center"
        />
      </CCol>
      <CCol sm="11">
        <CRow>
          <CCol sm="3">
            <multiselect
                :disabled="!allowEdit"
                v-model="accountOwner.title"
                @input="updateItem"
                :options="Object.keys(this.titleOptions)"
                :custom-label="(item) => this.titleOptions[item]"
                :close-on-select="true"
                :allow-empty="true"
                placeholder="Title"
                select-label="Select"
                deselect-label="Remove"
            />
            <CRow>
              <small class="form-text text-muted w-100">Title</small>
            </CRow>
          </CCol>
          <CCol sm="4">
            <CInput
                v-model="accountOwner.firstName"
                v-on:change="updateItem"
                placeholder="Enter Name"
                description="First Name"
                :is-valid="notNull"
                tooltip-feedback
                :readonly="!allowEdit"
                data-sel="ipt-fname"
            />
          </CCol>
          <CCol sm="5">
            <CInput
                v-model="accountOwner.lastName"
                v-on:change="updateItem"
                placeholder="Enter Name"
                description="Last Name"
                :is-valid="notNull"
                tooltip-feedback
                :readonly="!allowEdit"
                data-sel="ipt-lname"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="6">
            <CInput
                v-model="accountOwner.email"
                v-on:change="updateItem"
                placeholder="Enter Email"
                description="Email"
                :is-valid="validEmail"
                tooltip-feedback
                invalid-feedback="Please enter a valid email address"
                :readonly="!allowEdit"
                data-sel="ipt-email"
            />
          </CCol>
          <CCol sm="6">
            <CInput
                v-model="accountOwner.phone"
                v-on:change="updateItem"
                placeholder="Enter phone number"
                description="Phone"
                :is-valid="notNull"
                tooltip-feedback
                invalid-feedback="Please enter a phone number"
                :readonly="!allowEdit"
                data-sel="ipt-phone"
            />
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>

<script>
const clonedeep = require('lodash.clonedeep')

export default {
  name: "AccountOwnerForm",
  props: ["accountOwnerIn"],
  data () {
    return {
      titleOptions: {
        'dr': 'Dr.'
      }
    }
  },
  methods: {
    updateItem(){
      const data = clonedeep(this.accountOwner)
      this.$emit('update-item', data);
    },
    notNull(val) {
        return val !== undefined && val !== null && val !== ''
    },
    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return email? re.test(email): null;
    }
  },
  computed: {
    accountOwner(){
      return this.accountOwnerIn
    },
    allowEdit: {
      get: function() {
        return this.$store.state.allowEdit
      },
      set: function (){
      }
    }
  },
  watch: {
    AccountOwnerIn(){
      this.updateItem();
    }
  }
}
</script>

<style scoped>
  .multiselect {
    border-width: 2pt;
    list-style-type: none;
    margin-bottom: 1pt;
  }
  .col-img{
    padding: 0;
  }
</style>
