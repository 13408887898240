<template>
  <div>
    <CModal
        data-sel="mdl-add-ao"
        title="New Account Owner"
        :show.sync="showModal"
        @update:show="closeAddModal"
    >
      <CAlert
          v-if="error"
          color="danger"
          closeButton
          @update:show="removeError">
        {{error}}
      </CAlert>
      <AccountOwnerForm
          v-bind:accountOwnerIn="accountOwner"
          v-on:update-item="updateItem"
          v-on:error="catchError"
      />
    </CModal>
  </div>
</template>

<script>
import gql from "graphql-tag";
import AccountOwnerForm from "./AccountOwnerForm";

const createAccountOwner = gql`
  mutation createAccountOwner($accountOwner: AccountOwnerInput){
        createAccountOwner(accountOwner: $accountOwner) {
            email
            firstName
            lastName
            nid
        }
  }
`

export default {
  name: "AccountOwnerAdd",
  components: {
    AccountOwnerForm
  },
  data(){
    return {
      accountOwner: {},
      accountOwnerSave: {},
      error: null
    }
  },
  computed: {
    showModal: {
      get: function() {
        return this.$store.state.addModalShow
      },
      set: function (){
      }
    }
  },
  methods: {
    updateItem(data){
      this.accountOwnerSave = data
    },
    closeAddModal(status, evt, accept){
      if (accept) {
        this.$apollo.mutate({
          mutation: createAccountOwner,
          variables: {
            accountOwner: this.accountOwnerSave
          }
        })
        .then(res => {
          this.$emit('add-item', res.data.createAccountOwner);
          this.accountOwner = {};
          this.accountOwnerSave = {};
          this.$store.commit('set', ['addModalShow', false]);
        })
        .catch(error => {
          console.error(error);
          this.error = error.message;
        })
       }else{
        this.accountOwner = {};
        this.accountOwnerSave = {};
        this.$store.commit('set', ['addModalShow', false]);
       }
    },
    catchError(event){
      this.error = event
    },
    removeError(hide){
      if(!hide){
        this.error = null
      }
    }
  }
}
</script>
