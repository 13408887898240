import { render, staticRenderFns } from "./AccountOwners.vue?vue&type=template&id=55bc9354&scoped=true"
import script from "./AccountOwners.vue?vue&type=script&lang=js"
export * from "./AccountOwners.vue?vue&type=script&lang=js"
import style0 from "./AccountOwners.vue?vue&type=style&index=0&id=55bc9354&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55bc9354",
  null
  
)

export default component.exports