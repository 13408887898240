<template>
  <div data-sel="page-ao">
    <CSpinner v-if="loading" color="info"/>
    <CAlert
        v-if="error"
        color="danger"
        closeButton
        @update:show="removeError">
      {{error}}
    </CAlert>
    <CRow class="add-button-row">
      <CButton
          data-sel="btn-add-ao"
          variant="outline"
          color="info"
          @click="openAddModal">
        Add Account Owner
      </CButton>
    </CRow>
    <AccountOwnerAdd
        v-on:add-item="addItemLocal"
    />
    <CCardBody>
      <CDataTable
        :items="this.accountOwners.data"
        :fields="fields"
        table-filter
        items-per-page-select
        :items-per-page="itemsPerPage"
        v-on:update:sorter-value="updateOrder"
        v-on:update:table-filter-value="updateFilter"
        v-on:pagination-change="updatePagination"
        hover
        sorter
        :pagination="false"
      >
        <template #edit_item="{item}">
          <td class="py-2">
            <TableButtonGroup
                v-on:view="viewItem(item)"
                v-on:edit="editItem(item)"
                v-on:delete-prompt="toggleDeleteItemModal(item)"
            />
            <DeleteModal
                entity="account-owner"
                :name="deleteItemName"
                :nid="deleteItemID"
                :show.sync="showDeleteItemModal"
                v-on:delete="deleteItem(item)"
                v-on:cancel-delete="toggleDeleteItemModal(item)"
            />
          </td>
        </template>
      </CDataTable>
      <CPagination
          :activePage.sync="activePage"
          :pages="pages"
      />
    </CCardBody>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import DeleteModal from "@/components/common/DeleteModal";
import TableButtonGroup from "@/components/common/TableButtonGroup";
import AccountOwnerAdd from "@/apps/account_owners/components/AccountOwnerAdd";

const queryAccountOwners = gql`
query accountOwners($offset: Int, $limit: Int, $order: String, $q: String) {
  accountOwners(offset: $offset, limit: $limit, order: $order, q: $q) {
    totalCount
    data {
      nid
      email
      firstName
      lastName
    }
  }
}
`
const deleteAccountOwner = gql`
  mutation deleteAcountOwner($nid: ID!){
    deleteAccountOwner(nid: $nid)
  }
`

const fields = [
    {
      key: 'edit_item',
      label: '',
      _style: 'width:1%',
      sorter: false,
      filter: false
    },
    { key: 'nid', label: 'NID', _style:'min-width:50px'},
  { key: 'firstName', _style:'min-width:100px;' },
  { key: 'lastName', _style:'min-width:100px;' },
  { key: 'email', _style:'min-width:200px' }
  ]

export default {
  name: "AccountOwners",
  components: {
    AccountOwnerAdd,
    DeleteModal,
    TableButtonGroup
  },
  data() {
    return {
      waiting: false,
      accountOwners: {},
      orderColumn: "updatedAt",
      orderPrefix: "-",
      tableFilter: null,
      itemsPerPage: 20,
      queryOffset: 0,
      activePage: 1,
      error: null,
      fields: fields,
      showDeleteItemModal: false,
      deleteItemID: null,
      deleteItemName: null
    }
  },
  methods: {
    updateOrder(event){
      this.orderColumn = event.column;
      this.orderPrefix = event.asc ? '': '-';
    },
    updateFilter(event){
      this.tableFilter = event;
    },
    updatePagination(event){
      this.itemsPerPage = event;
    },
    openAddModal(){
      this.$store.commit('set', ['allowEdit', true]);
      this.$store.commit('set', ['addModalShow', true]);
    },
    getItemName(item){
      return `${item.firstName} ${item.lastName}`
    },
    editItem(item){
      this.$store.commit('set', ['allowEdit', true]);
      this.$router.push({ path: `/account-owners/account-owner/${item.nid}`});
    },
    viewItem(item){
      this.$store.commit('set', ['allowEdit', false]);
      this.$router.push({ path: `/account-owners/account-owner/${item.nid}`});
    },
    toggleDeleteItemModal(item){
      this.deleteItemID = item.nid;
      this.deleteItemName = this.getItemName(item);
      this.showDeleteItemModal = !this.showDeleteItemModal
    },
    deleteItem(item){
      this.$apollo.mutate({
        mutation: deleteAccountOwner,
        variables: {
          nid: this.deleteItemID
        },
        error(error){
          console.error(error);
          this.error = error.message;
          this.deleteItemID = null;
          this.deleteItemName = null;
          this.showDeleteItemModal = false;
        }
      })
      .then(res => {
        this.waiting = true;
        setTimeout(()=> {
          this.$apollo.queries.accountOwners.refetch(() => {
            return {
              offset: this.offset,
              limit: this.itemsPerPage,
              order: this.sortOrder,
              q: this.tableFilter
            }
          })}, 500);
        this.waiting = false;
        this.deleteItemID = null;
        this.deleteItemName = null;
        this.showDeleteItemModal = false;
      })
    },
    updateItemLocal(data){
      this.accountOwners.data = this.accountOwners.data.filter(function(item) {return item.nid === data.nid ? data : item });
    },
    removeError(hide){
      if(!hide){
        this.error = null
      }
    },
    addItemLocal(item){
      this.accountOwners.data.pop();
      this.accountOwners.data = [item].concat(this.accountOwners.data);
    },
    fetchData(){
      if(this.doRefetch){
        this.queryOffset = 0
        this.tableFilter = null
        this.orderPrefix = '-'
        this.orderColumn = 'updatedAt'
        this.waiting = true
        setTimeout(()=> {
          this.$apollo.queries.accountOwners.refetch(() => {
            return {
              offset: this.offset,
              limit: this.itemsPerPage,
              order: this.sortOrder,
              q: this.tableFilter
          }});
        }, 500);
        this.waiting = false;
        this.$store.commit('set', ['refetchData', false]);
      }
    }
  },
  apollo: {
    accountOwners: {
      query: queryAccountOwners,
      variables(){
        return {
          offset: this.offset,
          limit: this.itemsPerPage,
          order: this.sortOrder,
          q: this.tableFilter
        }
      },
      error(error) {
        console.error(error);
        this.error = error.message;
      }
    }
  },
  computed:{
    doRefetch(){
      return this.$store.state.refetchData
    },
    loading(){
      return this.$apollo.queries.accountOwners.loading || this.waiting
    },
    sortOrder: {
      get() {
        return `${this.orderPrefix}${this.orderColumn}`
      },
      set(){

      }
    },
    pages:{
      get() {
        return Math.ceil(this.accountOwners.totalCount / this.itemsPerPage)
      },
      set(){

      }
    },
    offset:{
      get() {
        return this.itemsPerPage*(this.activePage-1)
      },
      set(){

      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.fetchData())
  },
}
</script>

<style scoped>
  .add-button-row{
    justify-content: start;
    margin-left: 1.25rem;
  }
</style>